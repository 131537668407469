<template>
  <base-layout>
    <!--header-bar></header-bar-->

    <div class="day-selector">
      <IonSegmentButton class="day-selector-item" style="min-width:50px; width: 50px;max-width:50px"
                        @click="changeToGrid()">
        <ion-icon style="color: white" :icon="gridOutline"></ion-icon>
      </IonSegmentButton>
      <div class="day-selector-item position-relative" v-for="(value, key) in days" :key="key" :value="key"
           style="" mode="md" @click="activeDay = key">
        <div class="day-text d-md-none position-relative">
          <div class="small-badge" v-if="searchDone">{{ value.filteredSessionCount }}</div>

          {{ moment(key).format('ddd') }}
        </div>
        <div v-if="activeDay == key" class="day-number-selected">
          <span class="punkt-selected d-md-none">{{ moment(key).format('D') }} </span>

          <div style="color:white"
               class="d-none d-md-flex justify-content-center large-selected">
            <div class="label-wrapper">
              <div class="label-badge position-absolute top-0 end-0" v-if="searchDone">
                {{ value.filteredSessionCount }}
              </div>
              <span>{{ moment(key).format('dddd, MMMM D') }}</span>
            </div>
          </div>
        </div>
        <div v-else class="day-number">
          <span class="d-md-none">{{ moment(key).format('D') }}</span>

          <div style="color:white"
               class="d-none d-md-flex justify-content-center">
            <div class="label-wrapper">
              <div class="label-badge position-absolute top-0 end-0" v-if="searchDone">
                {{ value.filteredSessionCount }}
              </div>
              <span>{{ moment(key).format('dddd, MMMM D') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: none; margin:10px 5px">
      <div style="margin-right: 5px"><font-awesome-icon :icon="['fad', 'circle']" class="type-icon" style="color:#000;font-size:1.2rem;margin:0 0 0 5px;" /> &nbsp;= Sitzungen mit Experten aus dem Pflegeberuf</div>
    </div>

    <!--div class="day-selector">

      <div class=" d-flex justify-content-start px-3"
                        style="min-width:50px; width: 350px;max-width:350px; color:white"
                        @click="hideIndustrySessions()" >
        <ion-icon style="color: white; width:25px;height:25px" class="me-2" :icon="hideIndustry ? funnel : funnelOutline
"></ion-icon>
        {{ hideIndustry ? 'Industriesitzungen einblenden' : 'Industriesitzungen ausblenden' }}

      </div>
    </div-->

    <ion-searchbar class="sessionlist-searchbar"
                   show-cancel-button="focus"
                   @ionChange="filter(search)"
                   :value="search"
                   :placeholder="wordings[currentLanguage].SEARCH_PROGRAMME"
                   @ionInput="search = $event.target.value;"
    ></ion-searchbar>

    <ion-content>
      <div>
        <Placeholder v-if="!activeDay || !days[activeDay] || !days[activeDay].sessions" with-hint="true"
                     hint="Loading sessions..."
                     style="padding:20px"></Placeholder>

        <div class="p-3" v-if="searchDone  && days[activeDay].filteredSessionCount == 0">
          No sessions found for "{{ search }}" on this day.
        </div>
        <div class="container-fluid p-0 p-md-3" v-if="activeDay && days[activeDay] && days[activeDay].sessions">
          <div
              class="row row-cols-1 row-cols-md-3  row-cols-lg-4 row-cols-xl-5 g-2 g-md-4 row-eq-height justify-content-start">
            <template v-for="session in getFilteredSessions()" :key="session.id">
              <template v-if="!hideIndustry">
                <session-item :session="session" v-if="session"></session-item>
              </template>
              <template v-if="hideIndustry">
                <session-item :session="session" v-if="session && session.is_scientific"></session-item>
              </template>

            </template>

          </div>
        </div>
      </div>
    </ion-content>
  </base-layout>
</template>

<script>
import {IonContent, IonSearchbar, IonSegmentButton, alertController} from '@ionic/vue';
import {defineComponent} from 'vue';
import {menuController} from "@ionic/vue";
import {mapActions, mapState} from 'vuex';
import {gridOutline, chevronForward, chevronBack, funnelOutline, funnel,toggle, toggleOutline} from 'ionicons/icons';
import Placeholder from "@/components/Placeholder";
import moment from 'moment';
import SessionItem from "@/components/SessionItem"
//moment.locale("de");

const config = require('@/config')

export default defineComponent({
  name: 'Home',
  data() {
    return {
      sessions: [],
      days: {},
      activeDay: '',
      search: "",
      filtered: [],
      searchDone: false,
      isApp: config.is_app,
      hideIndustry: false
    }
  },

  setup() {
    return {
      gridOutline,
      chevronForward,
      chevronBack,
      funnelOutline, funnel,toggle,toggleOutline
    }
  },
  components: {
    IonContent,
    IonSegmentButton,
    IonSearchbar,
    Placeholder,
    'session-item': SessionItem,
  },
  computed: {
    ...mapState('sync', ['updateFinished']),
    ...mapState('wordings', ['wordings', 'currentLanguage']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion', 'primaryColor', 'menuheader']),

    filteredSessions: function () {
      return this.filtered.length ? this.filtered : this.sessions;
    }
  },
  methods: {
    ...mapActions('sessions', ['getAllSessions', 'getSessionListLive', 'getDays']),
    ...mapActions('googleanalytics', ['trackWithLabel']),
    openMenu() {
      menuController.open("app-menu")
    },
    hideIndustrySessions() {
      this.hideIndustry = !this.hideIndustry
    },

    filter(term) {
      if (term.length > 0) {
        this.filtered = this.sessions.filter(session =>
            Object.keys(session).some(k =>
                session[k]
                    ? session[k].toString().toLowerCase().includes(term.toLowerCase())
                    : ""
            )
        );
        this.filtered = this.groupList(this.filtered, 'start_time');
        this.updateDays(this.filtered);
        this.searchDone = true;
      } else {
        this.filtered = [];
        this.searchDone = false;
        this.resetList();
      }
    },

    getFilteredSessions: function () {
      let returnList = this.searchDone ? this.days[this.activeDay].filteredSessions : this.days[this.activeDay].sessions;
      return returnList;
    },
    resetList() {
      this.days = {};
      this.prepareDays(this.sessions);
    },
    async fetchSessions() {
      this.sessions = (await this.getSessionListLive()).sort((a, b) => {
        return a.location_order - b.location_order;
      })

      this.sessions = this.sessions.sort((a, b) => {
        return new Date(a.start_time) - new Date(b.start_time);
      });

      this.sessions = this.groupList(this.sessions, 'start_time');

      this.days = {};
      this.prepareDays(this.sessions);
    },
    groupList(list, selector) {
      let letters = [];
      let currentCharCode = '2010-01-01T00:00:00'; //$filter('date')(date, format, timezone)
      let sortedList = [];

      function addLetter(code, scientific) {
        let letter = code
        sortedList.push({
          isHead: true,
          head: letter,
          start_time: letter,
          is_scientific: scientific
        });
        letters.push(letter);
      }

      (list || []).forEach(function (item) {
        if (item[selector]) {
          let itemCharCode = item[selector];
          let difference = new Date(itemCharCode) - new Date(currentCharCode);
          if (difference > 0)
            addLetter(itemCharCode, item.is_scientific);
          currentCharCode = itemCharCode;
          sortedList.push(item);
        }
      });
      return sortedList;
    },
    prepareDays(sessions) {
      for (let i = 0; i < sessions.length; i++) {
        let session = sessions[i];
        if (session.start_time) {
          let start = session.start_time.split('T')[0];
          if (!this.activeDay) {
            this.activeDay = start;
          }
          if (!this.days[start]) {
            this.days[start] = {};
            this.days[start].label = moment(start).format('dddd, MMMM D')
            this.days[start].sessions = [];
            this.days[start].filteredSessions = [];
            this.days[start].filteredSessionCount = 0;

          }
          this.days[start].sessions.push(session);
        }
      }
    },
    updateDays(sessions) {
      for (let key in this.days) {
        this.days[key].filteredSessions = [];
        this.days[key].filteredSessionCount = 0;
      }
      console.log('days updated', this.days)

      for (let i = 0; i < sessions.length; i++) {
        let session = sessions[i];
        if (session.start_time) {
          let start = session.start_time.split('T')[0];
          if (!this.activeDay) {
            this.activeDay = start;
          }

          if (!session.isHead) {
            this.days[start].filteredSessionCount++;
          }
          this.days[start].filteredSessions.push(session);
        }
      }
      console.log('days updated', this.days)
    },

    dayUp() {

      let backUpDay = this.activeDay
      let nextDay;
      for (let i = 0; i < Object.keys(this.days).length; i++) {
        if (this.activeDay == Object.keys(this.days)[i]) {
          nextDay = Object.keys(this.days)[i + 1]
        }
      }
      if (nextDay == undefined) {
        nextDay = backUpDay
      }
      this.activeDay = nextDay;
    },

    dayDown() {
      let backUpDay = this.activeDay
      let prevDay;


      for (let i = 0; i < Object.keys(this.days).length; i++) {
        if (this.activeDay == Object.keys(this.days)[i]) {
          prevDay = Object.keys(this.days)[i - 1]
        }
      }


      if (prevDay == undefined) {
        prevDay = backUpDay
      }
      this.activeDay = prevDay;
    },


    getChairPicture(session) {
      let link = ''
      if (session.chair && session.chair[0] && session.chair[0].vitae_picture) {
        link = 'https://fileserver-188ab.kxcdn.com/file/' + session.chair[0].vitae_picture.split('/')[4];
      }
      return link;
    },

    changeToGrid() {
      this.$router.push('/app/gridList');
    },

    async showDisclaimer(session) {
      if (session.disclaimer && session.disclaimer.length > 10) {
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: session.title,
              message: '<strong>' + session.disclaimer + '</strong>',
              buttons: [
                {
                  text: 'Decline',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  },
                },
                {
                  text: 'Accept',
                  id: 'confirm-button',
                  handler: () => {
                    this.trackWithLabel(session.title);
                    this.$router.push('/app/sessions/' + session.id);
                  },
                },
              ],
            });
        return alert.present();
      } else {
        this.$router.push('/app/sessions/' + session.id);
      }

    }


  },
  async created() {
    this.days = await this.getDays();
    this.fetchSessions();
    //this.activeDay = this.$route.params.day;
    this.moment = moment;
    this.moment.locale(this.currentLanguage || 'en');

  },
  watch: {
    updateFinished: async function (newVal) {
      if (newVal) {
        this.fetchSessions()
      }
    },
    '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(to) {

        let from = localStorage.getItem('lastViewedRoute')
        if (to.name == 'SessionListDay') {
          this.days = await this.getDays();
          if (this.$route.params.day && from != 'Session Detail') {
            this.activeDay = this.$route.params.day == 0 ? this.days[0] : this.$route.params.day;
          } else if (this.$route.params.day && from == 'Session Detail') {
            this.activeDay = localStorage.getItem('lastViewedDay');
          } else {
            this.activeDay = localStorage.getItem('lastViewedDay');
          }

          console.log('active day', this.activeDay)

          this.moment = moment;
          this.fetchSessions();
        }

        if (to.name == 'SessionList') {
          console.log('SESSION LIST VIEW')
          this.days = await this.getDays();
          this.activeDay = localStorage.getItem('lastViewedDay') || this.$route.params.day || this.days[0];

          this.moment = moment;
          this.fetchSessions();
        } else {
          if (this.activeDay) {
            localStorage.setItem('lastViewedDay', this.activeDay)
          }
        }

      }
    },
  },
});
</script>
<style lang="scss">


.day-selector {
  width: 100%;
  padding: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.day-selector:hover {
  cursor: pointer;
}

.day-selector-item {
  text-align: center;
  width: 100%;
  position: relative;
  display: block;
}


.day-text {
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
}

.day-number {
  width: 100%;
  color: white;

}

.day-number-selected {
}

.punkt-selected {
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}

.large-selected {
  font-weight: 900;
  text-decoration: underline;
}

.label-wrapper {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 15px;

  .label-badge {
    font-size: 0.7rem;
    background: #fff;
    color: black;
    border-radius: 25px;
    padding: 0 5px;
  }
}

.small-badge {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 0.8rem;
  background: #fff;
  color: black;
  border-radius: 25px;
  padding: 0 5px;
}

.label-wrapper {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 15px;

  .label-badge {
    font-size: 0.7rem;
    background: #fff;
    color: black;
    border-radius: 25px;
    padding: 0 5px;
  }
}

.small-badge {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 0.8rem;
  background: #fff;
  color: black;
  border-radius: 25px;
  padding: 0 5px;
}

</style>
